.card-body-funciones {
    margin-left: 1rem;
    margin-right: 1rem;
}
.container-pasos {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-left: 0px;
    margin-right: 0px;
}

.btn-card {
    width: auto !important;
}

@media (min-width: 769px) {
    .card-body-funciones-pasos p{
        line-height: 2rem;
    }
    .card-body-funciones {
        margin-left: 7rem;
        margin-right: 7rem;
    }
    .container-pasos {
        font-size: 1.25rem;
    }
}

