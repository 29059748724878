@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

body {
    background-color: #F3F3F3;
}

.rounded {
    border-radius: 3rem !important;
}

h1 {
    opacity: 1 !important;
    color: #064EB4;
}

h2 {
    opacity: 1 !important;
    color: white;
    font-size: 32px !important;
}

h3 {
    opacity: 1 !important;
    color: #064EB4;
}


#boton {
    opacity: 1;
}


.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}


.btn-primary {
    color: #fff;
    background-color: #074FB5;
    border-color: #FFFFFF !important;
    height: 40px;
    width: 60%;
    border-radius: 1.2vh;
    margin-left: auto;
    margin-right: auto;
}

.btn-primary-val {
    color: #fff;
    background-color: #074FB5;
    border-color: #FFFFFF !important;
    height: 40px;
    width: auto;
    border-radius: 1.2vh;
    margin-left: auto;
    margin-right: auto;
}

.primary-line-button-val {
    border-radius: 1.2vh;
    border: 1px solid #064EB4 !important;
    background-color: transparent !important;
    color: #064EB4 !important;
    margin: 0 5px;
    width: auto;
    padding: 7px 30px !important;
    padding-bottom: 2%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}

.reenviocodigo {
    border-top: 1px solid #808080;
    padding-top: 2%;
    padding-bottom: 2%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.form-control:focus {
    color: white;
    background-color: transparent;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000!important;
    background-clip: padding-box;
    border: 1px solid #064EB4;
    border-radius: 1.3vh;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-val {
    display: block;
    width: 30%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    text-align:center;
    color: #000 !important;
    background-clip: padding-box;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #064EB4 !important;
    border-radius: 1.3vh;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.5) !important;
}

.grow {
    transition: all .2s ease-in-out;
}

    .grow:hover {
        transform: scale(1.02);
    }

.reg {
    height: 50px;
    width: 50px;
}

.text-gris {
    color: #808080;
    font-size: 14px;
}

.text-muted {
    color: rgba(246, 76, 114) !important;
    font-size: 14px;
    text-shadow: 2px 2px 2px rgb(50, 50, 50);
}

.custom-select {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000;
    background-color: transparent;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 1vh;
    opacity: 0.8;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

    .custom-select:focus{
        background-color: transparent;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    
    .custom-select > option {
        color: #000 !important;
        border: 1px solid #064EB4 !important;
    }

@media (min-width: 992px) {
    #card {
        max-width: 90%;
    }
}
