
.form-control {
  margin-left: auto;
  margin-right: auto;
	display: block;
	width: 80%;
	height: calc(1.5em + 0.75rem);
	padding: 0.375rem 0.75rem;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5;
	color: #ffffff;
	background-color: transparent;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 1.3vh;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control:focus {
	color: white;
	background-color: transparent;
	border-color: #80bdff;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.grid-container {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.8) !important;
  border-radius: 0.8rem !important;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1px;
  background-color: rgba(36, 37, 130, 0.3) !important;
}

#BusquedaPics {
	border-right: 1px solid #dee2e6;
	height: 100%;
}

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px #80bdff;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px #80bdff;
}