@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 18px;
}

.text3 {
    display: none;
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-size: 21px;
    color: #064EB4;
    font-weight: bolder;
}

.text2 {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    font-size: 18px;
    height: auto;
    width: auto;
    border: 5px;
    border-radius: 25px;
    color: rgba(253, 253, 253, 0.6);
    font-weight: 700;
}

    .text2:hover {
        background-color: #064EB4;
        color: #F3F3F3 !important;
    }


.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .4);
}

.Ico {
    border-radius: 25px;
    background-color: #F3F3F3;
    transition: all .2s ease-in-out;
    width: 80px;
}

    .ico:hover {
        transform: scale(1.05);
    }

.nav-item {
    font-size: 18px;
    font-weight: normal;
    margin: 0 0.5rem;
}

.nav-item-primary {
    border-radius: 6px;
    color: #064EB4 !important;
    padding: 7px 30px !important;
}

.nav-item-primary-line {
    border-radius: 6px;
    background-color: transparent !important;
    color: #064EB4 !important;
    padding: 7px 30px !important;
}

@media (min-width: 768px) {
    html {
        font-size: 14px;
    }

    .text3 {
        display: inline-block;
    }

    .nav-item-primary {
        background-color: #064EB4;
        color: #F3F3F3 !important;
    }

    .nav-item-primary-line {
        background-color: #F3F3F3;
        color: #064EB4 !important;
    }
}

@media (min-width: 992px) {
    .text3 {
        font-size: 26px;
    }
}
