a:hover { text-decoration: none; }

.container-card {
    background: #fff;
    border-radius: 1.25rem;
    min-height: 150px;
    min-width: 170px;
    cursor: pointer;
    position: relative;
}


.card-image {
    position: absolute;
    z-index: 10;
}

.card-image img {
    margin-top: -50%;
}

.card-body {
    margin-top: 50px;
    /* position: absolute; */
    z-index: 5;
    color: black;
}

.btn-card {
    width: 150px !important ;
    text-align: center;       
    font-weight: bold !important ;
}

.point {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #0366d6;
    margin: 0.25rem;
}

.indicator-active {
    background-color: #0366d6;
    width: 1rem;
    height: 1rem;
}

.indicator {
    margin-top: 2rem;
}

@media (min-width: 769px) {
    .animation {
        transition: transform .3s ease-in-out;
    }
    .animation:hover {
        transform: rotate(-7deg) scale(1.1);
    }
    
}