.card-img-1 {
    width: 200px;
}

.card-img-2 {
    width: 250px;
}
.card-img-3 {
    height: 200px;
}


@media (min-width: 769px) {
    .card-img-1 {
        width: 300px;
    }
    .card-img-2 {
        width: 400px;
    }
    .card-img-3 {
        height: 400px;
    }
    
}
