
body {
    background-size: 211%;
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: -143px -80px;
    background-image: url(../Resources/bg-home-sin-estrellas.png);
}

main.container-fluid-lm {
    overflow: hidden;
    position: relative;
}

.logo a {
    border: 0;
}


.encabezado h1, .encabezado h2 {
    text-align: right
}

.encabezado h2 {
    color: #333333;
    font-size: 39px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-family: Poppins;
    letter-spacing: -0.03em;
    line-height: 42px;
    margin-bottom: 0;
    text-align: center
}

.encabezado h1 {
    color: #064EB4;
    font-size: 44px;
    font-family: 'Poppins', sans-serif;
    font-weight: 900;
    line-height: 44px;
    text-align: center
}

.form-control {
    width: 100% !important;
}

.encabezado p {
    color: #333333;
    font-size: 1.0rem;
    line-height: 1.5rem
}



.logo img {
    max-width: 77px;
    height: auto;
}

.cocinero {
    margin: auto;
    overflow: hidden;
}

.btn-primary {
    color: #fff !important;
    background-color: #064EB4;
    border-color: #064EB4;
    border-radius: 2.25rem;
    font-size: 0.8rem;
}

h3.title {
    color: #064eb4;
    font-weight: 900;
    font-size: 35px;
    margin: 0 0 40px 0;
}


.container-fluid.bg-frutas {
    /*background: #c8dbfc;*/
    /*background-image: url("../Resources/bg-frutas-mbl.png");*/
    background-size: contain;
    background-repeat: repeat;
    margin-bottom: -150px;
}

    .container-fluid.bg-frutas::before {
        content: url(../Resources/frutas.png);
        display: block;
        width: 80px;
        position: absolute;
        left: -40px;
        top: 950px;
    }

    .container-fluid.bg-frutas::after {
        content: url(../Resources/frutas2.png);
        width: 80px;
        position: absolute;
        right: 20px;
        top: 900px;
        z-index:-1;
    }



.bg-frutas .col {
    background: #fff;
    border-radius: 1.25rem !important;
    border: 0;
    min-height: 200px
}



img.ico {
    max-width: 100%;
}

.ico {
    width: 100%;
    height: 100px;
    margin-bottom: -50px;
    z-index: 20;
}

.container-fluid.bg-celeste {
    background: url(../Resources/bg-celeste.png);
    padding-top: 150px !important;
    background-position: bottom;
    background-size: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

footer.blog-footer {
    background: #333333;
    color: #fff;
}


    footer.blog-footer img {
        max-width: 150px;
    }

    footer.blog-footer p {
        margin-bottom: 0
    }

.carousel-caption p {
    background: #00000085;
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 20px;
}

.carousel-indicators button {
    background: red !important;
}

.container-fluid.conocenos {
    margin-top: -40px;
}



    .container-fluid.conocenos img {
        max-width: 64px;
        height: auto
    }

.carousel-indicators li {
    background: #064eb4 !important;
    border-radius: 50px;
    width: 23px !important;
}

.mbl {
    display: block
}

.dsktp {
    display: none
}

.container.mbl {
    padding: 0;
}


.registro footer.blog-footer.p-2 {
    position: absolute;
    width: 100%;
    bottom: 0;
}

div#card {
    background: #fff;
    max-width: 500px;
}

.dropdown.bootstrap-select {
    width: 100% !important;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #21252996;
}


.selectpicker {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #21252996;
}


.bootstrap-select .btn-light {
    color: #21252996;
    font-size: 1rem;
}

.bootstrap-select .show > .btn-light.dropdown-toggle {
    color: #21252996;
    font-size: 1rem;
}

@media (min-width: 769px) {

    body {
        background-size: cover;
        background-position: top right;
        background-image: url(../Resources/bg-home-sin-estrellas.png);
    }

    header.blog-header {
        max-width: 1200px;
        margin: auto;
    }

    .col-4.pt-1.align-items-center.logo {
        text-align: left;
    }

    main.container-fluid-lm .container {
        max-width: 1200px;
        margin: auto;
    }

    .mbl {
        display: none
    }

    .dsktp {
        display: block
    }

    main.container-fluid-lm .container {
        max-width: 1200px;
        margin: auto;
        padding: 20px
    }

    .micocinero {
        margin-top: 20px !important;
        max-width: 80%;
    }

    .cocinero img {
        width: 100%;
        max-width: 800px;
    }


        .cocinero img.dsktp {
            width: 100%;
        }

    main.container-fluid-lm {
        position: relative;
    }

    .encabezado .col-md-5.px-0 {
        vertical-align: middle;
        margin-top: 5%;
    }

    .encabezado h2 {
        text-align: left;
    }

    .encabezado h1 {
        text-align: left;
    }

    .encabezado p {
        text-align: left;
    }

    .col-4.d-flex.justify-content-end.align-items-center {
        z-index: 30;
    }

    .container-fluid.bg-frutas .row {
        max-width: 1200px;
        margin: auto;
    }

    .container-fluid.bg-celeste .row {
        max-width: 1200px;
        margin: auto;
    }

    .container-fluid.bg-frutas {
        padding-top: 0 !important;
        /*background-image: url(../Resources/bg-frutas.png);*/
        background-color: transparent;
        padding-top: 90px !important;
    }

        .container-fluid.bg-frutas::before {
            content: url(../Resources/frutas-dsktp.png);
            display: block;
            width: 150px;
            position: absolute;
            left: -72px;
            top: 702px;
        }

        .container-fluid.bg-frutas::after {
            content: url(../Resources/frutas-dsktp-2.png);
            width: 150px;
            position: absolute;
            right: 150px;
            top: 700px;
        }

    .bg-frutas .col {
        padding: 40px 50px !important;
        margin: 0 5%;
    }

    .container-fluid.bg-celeste {
        background-size: cover;
        padding: 200px 0 100px 0 !important;
        background-color: #fff !important;
        padding-bottom: 211px !important;
    }

    .container-fluid.conocenos {
        background: #fff;
        padding: 0 60px 60px 60px !important;
        margin-top: -150px;
    }
    .container.conocenos {
        background: #fff;
        padding: 0 60px 60px 60px !important;
    }

    .btn {
        font-size: 1.1rem;
    }

    .col-7.cocinero {
        margin-top: -110px;
    }

    .encabezado a.btn.btn-primary {
        float: left;
    }

    .ayudamos p {
        text-align: center;
        margin-top: 20px;
    }

    h3.title {
        color: #064eb4;
        font-weight: 900;
        font-size: 35px;
    }

        h3.title::before, h3.title::after {
            display: inline-block;
            overflow: hidden;
            width: 10%;
            height: 52px;
            vertical-align: middle;
            background-image: url(../Resources/linea.png);
            background-repeat: repeat-x;
            background-position: center center;
            content: '';
        }

        h3.title::before {
            margin-right: 20px
        }

        h3.title::after {
            margin-left: 20px
        }

    .video p {
        text-align: left
    }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10-specific styles go here */
    .container-fluid.bg-frutas::before {
        content: url(../Resources/frutas-dsktp.png);
        display: block;
        width: 150px;
        position: absolute;
        left: -72px;
        top: 150px;
    }

    .container-fluid.bg-frutas::after {
        content: url(../Resources/frutas-dsktp-2.png);
        width: 150px;
        position: absolute;
        right: -8px;
        top: 100px;
        float: right;
    }

    .bg-frutas.container-fluid {
        position: relative
    }
}

@media (max-width: 769px) {
    body {
        background-size: 211%;
        background-position: 0px -400px;
        height: 100vh;
        background-repeat: no-repeat;
        background-image: url(../Resources/bg-home-mobile.png);
    }
}
