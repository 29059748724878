.custom-sticky {
    background-color: #FFFFFF;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .4);
    width: 100%;
    margin: auto;
}

.selectRegion {
    background-color: #FFFFFF;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .4);
    border-radius: 6px;
}

@media (min-width: 992px) {
    .custom-sticky {
        width: 21cm
    }
}
