.card-body-funciones {
    margin-left: 1rem;
    margin-right: 1rem;
}
.container-pasos {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-left: 0px;
    margin-right: 0px;
}
.img-card {
    width: 250px;
}

@media (min-width: 769px) {
    .card-body-funciones {
        margin-left: 7rem;
        margin-right: 7rem;
    }
    
    .img-card {
        width: 400px;
    }

    /* .step-two-body > div > div > ul > li{
        margin-bottom: 2rem;
    }
    .step-two-body > div > div > p > li{
        margin-bottom: 2rem;
        text-align: left;
    } */
}

