.container-funciones {
    font-size: 1rem;
    margin-left: 0px;
    margin-right: 0px;
}

.card-body-funciones {
    margin-left: 1rem;
    margin-right: 1rem;
}

.btn {
    border-radius: 5px !important;
    font-size: 0.8rem !important;
    text-align: center;
    width: 250px;
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 769px) {
    .bg-container-funciones {
        background-image: url("../Resources/bg-cine.png");
        background-repeat: repeat;
    }
    .container-funciones {
        font-size: 1.25rem;    
    }
    .btn {
        font-size: 1rem !important;
    }

    .card-body-funciones {
        margin-left: 7rem;
        margin-right: 7rem;
    }
}
