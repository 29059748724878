.btn-card {
    width: auto !important;
}
.img-card {
    width: 250px;
}

.img-card-1 {
    width: 150px;
}

@media (min-width: 769px) {
    .img-card {
        width: 400px;
    }
    .img-card-1 {
        width: 300px;
    }
}