@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

.estilosInput {
    border: 0;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding-left: 0px;
    padding-right: 0px;
    justify-self: center;
    display: inline-block !important;
}

.inputSeccion {
    display: flex;
    justify-content: center;
}

.estilosSeccion{
    border-radius: 20px;
    padding: 10px;
    background-color: white;
}



