.partners {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
    border-radius: 1.25rem;
    max-width: 78rem;
}

.partners img {
    margin: 0.75rem;
}

#imagenConocenos {
    border-radius: 7%;
    width : 100%;
}

.carousel-inner {
    font-size: 0.75rem;
}

@media (min-width: 769px) {
    .partners {
        margin-left: 0;
        margin-right: 0;
        padding: 2rem;
    }
    #bg-estrellas {
        /*background: #c8dbfc;*/
        background-image: url("../Resources/bg-estrellas.png");
        background-size: contain;
        background-repeat: repeat;
        margin-bottom: -150px;
    }

}
@media (max-width: 769px) {
    body {
        background-size: 211%;
        height: 100vh;
        background-repeat: no-repeat;
        background-image: url(../Resources/bg-home-mobile.png);
    }
    #bg-estrellas {
        background-image: none;
    }
}