@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

.arasaacCopyR {
    padding: 1px;
    font-size: 0.7em;
}

.grid-container {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 1px;
    background-color: rgb(255, 255, 255);
}

    .grid-container > div {
        background-color: gainsboro;
        font-size: 1em;
    }

.grid-nested {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(200px, auto);
}

.categoria {
    background-color: orange;
    text-align: center;
    width: 100%;
}

.botonGuardar {
    white-space: normal;
    align-self: center;
    text-align: center;
    word-break: break-all;
    font-size: 18px;
    height: auto;
    width: auto;
    border: 5px;
    border-radius: 25px;
    color: #064EB4;
    font-weight: 700;
    margin: 5px;
    background-color: #E8E8E8;
}

    .botonGuardar:hover {
        background-color: #064EB4;
        color: rgba(253, 253, 253, 0.6);
    }
