.cardComercio {
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
    border-radius: 1.25rem;
    min-width: 29rem;
    width: 90%;
}

.cardFiltros {
    min-width: 19rem;
}

.botonEliminarFiltros {
    width: 20px;
    color: gray;
    background-color: transparent;
    font-size: 12pt;
    border-style: none;
}

.estilosFoto {
    background-color: white;
    border-radius: 20%;
}
   