* {
    font-family: 'Montserrat', sans-serif;
}

.ir-arriba {
    display: none;
    padding: 20px;
    background: #064EB4;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 10%;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .4);
    z-index: 1;
}

.counter-paginas {
    padding: 10px;
    background: #064EB4;
    font-size: 14px;
    color: #fff;
    cursor:default;
    position: fixed;
    top: 120px;
    left: 20px;
    border-radius: 10%;
}

.botonEliminarSeccion {
    width: 20px;
    color: gray;
    background-color: transparent;
    font-size: 12pt;
    border-style: none;
}

.botonRefrescarDatos {
    width: 60px;
    color: gray;
    background-color: transparent;
    font-size: 15pt;
    margin-top: 6px;
    position: absolute;
    top: 3px;
    right: 10px;
    border-style: none;
}

.botonEliminar {
    width: 20px;
    color: gray;
    background-color: transparent;
    font-size: 12pt;
    margin-top: 3px;
    margin-right: 3px;
    border-style: none;
    justify-self: flex-end;
    align-self: flex-end;
}

.botonEliminarIngrediente {
    width: 20px;
    color: gray;
    background-color: transparent;
    font-size: 10pt;
    border-style: none;
    align-self: flex-end;
}

    .botonEliminar .botonEliminarText {
        visibility: hidden;
        background-color: #FFCCCC;
        border: 1px;
        border-color: red;
        padding: 2px;
        color: red;
        position: absolute;
        z-index: 1;
        font-size: 10px;
        border-style: solid;
        border-radius: 10px;
        top: -20px;
        left: 105%;
    }

    .botonEliminar:hover .botonEliminarText {
        visibility: visible;
    }

.botonEditar {
    width: 20px;
    color: gray;
    background-color: transparent;
    font-size: 12pt;
    margin-top: 3px;
    margin-right: 25px;
    border-style: none;
}

    .botonEditar .botonEditarText {
        visibility: hidden;
        background-color: #FFCCCC;
        border: 1px;
        border-color: red;
        padding: 2px;
        color: red;
        position: absolute;
        z-index: 1;
        font-size: 10px;
        border-style: solid;
        border-radius: 10px;
        top: -20px;
        left: 105%;
    }

    .botonEditar:hover .botonEditarText {
        visibility: visible;
    }

.botonGuardar {
    white-space: normal;
    align-self: center;
    text-align: center;
    word-break: break-all;
    font-size: 18px;
    height: auto;
    width: auto;
    border: 5px;
    border-radius: 25px;
    color: #064EB4;
    font-weight: 700;
}

    .botonGuardar:hover {
        background-color: #064EB4;
        color: rgba(253, 253, 253, 0.6);
    }
