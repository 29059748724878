.primary-button {
    border-radius: 6px;
    background-color: #064EB4 !important;
    border-color: transparent !important;
    color: #F3F3F3 !important;
    margin: 0 5px;
    min-width: 235px;
    padding: 7px 30px !important;
    text-align: center !important;
}

.primary-button-small {
    border-radius: 6px;
    background-color: #064EB4 !important;
    border-color: transparent !important;
    color: #F3F3F3 !important;
    margin: 0 2px;
    min-width: 150px;
    padding: 7px 5px !important;
    text-align: center !important;
}

.primary-line-button {
    border-radius: 6px;
    border: 1px solid #064EB4 !important;
    background-color: transparent !important;
    color: #064EB4 !important;
    margin: 0 5px;
    min-width: 235px;
    padding: 7px 30px !important;
    text-align: center;
}

.add-button {
    border-radius: 6px;
    border: 2px solid #064EB4;
    background-color: #fff;
    color: #064EB4 !important;
    width: 100%;
    height: 100%;
    text-align: center;
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .4);
    height: fit-content;
}

